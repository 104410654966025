import naja from 'naja';
import Swiper, { Navigation } from 'swiper';

class SwiperExtension {
	constructor() {}

	initialize(naja) {
		naja.addEventListener('success', () => this.make());
		this.make();
	}

	make() {
		$('.card-swiper').each((i, el) => {
			const element = $(el);
			const id = Math.random()
				.toString(36)
				.replace(/[^a-z]+/g, '')
				.substr(0, 8);
			new Swiper(element.attr('id', id).find('.swiper')[0], {
				modules: [Navigation],
				spaceBetween: 20,
				slidesPerView: 1,
				breakpoints: {
					576: {
						slidesPerView: 2,
					},
					768: {
						slidesPerView: 3,
					},
					992: {
						slidesPerView: 4,
					},
				},
				navigation: {
					nextEl: '#' + id + ' .swiper-button-next',
					prevEl: '#' + id + ' .swiper-button-prev',
				},
			});
		});
	}
}

naja.registerExtension(new SwiperExtension());
