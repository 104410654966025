import naja from 'naja';

class ContentTruncate {
	constructor() {}

	initialize(naja) {
		naja.addEventListener('success', () => this.make.bind());
		this.make();
	}

	make() {
		let button = document.getElementById('item-content-button');
		if (button) {
			button.addEventListener('click', this.show);
		}
	}

	show(event) {
		let button = event.currentTarget;
		let isExpanded = button.getAttribute('aria-expanded');
		let target = document.getElementById(button.dataset.target);
		if (isExpanded === 'false') {
			target.style.height = 'auto';
			button.setAttribute('aria-expanded', 'true');
			button.innerHTML =
				'<strong>' + button.dataset.textReadLess + '</strong>';
		} else {
			let top = document.getElementById('article-top');
			if (top) {
				top.scrollIntoView({
					behavior: 'smooth',
				});
			}
			target.style.height = '0';
			button.setAttribute('aria-expanded', 'false');
			button.innerHTML =
				'<strong>' + button.dataset.textReadMore + '</strong>';
		}
	}
}

naja.registerExtension(new ContentTruncate());
