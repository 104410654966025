import '../styles/main.scss';

// NAJA
import naja from 'naja';

// Components
import './components/content-truncate';
import './components/oembed';
import './components/maps-links';
import './components/menu';
import './components/swiper';
import './components/lazy-load';

naja.initialize();

// LightBox
import GLightbox from 'glightbox';
window.GLightbox = new GLightbox({
	touchNavigation: true,
});

// Nette Forms
import netteForms from 'nette-forms';
window.Nette = netteForms;
netteForms.initOnLoad();

// search form
$('#search-form').on('submit', event => {
	event.preventDefault();
	event.stopPropagation();
});
