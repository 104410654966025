/* global YT */
import naja from 'naja';

class OEmbed {
	initialize(naja) {
		naja.addEventListener('success', this.make.bind(this));
		this.make();
	}

	make() {
		const elements = document.querySelectorAll('oembed');
		elements.forEach((el, index) => {
			const youtubeId = OEmbed.youtubeParseUrl(el.getAttribute('url'));
			if (youtubeId !== null) {
				const uniqueId = `youtube-${youtubeId}-${index}`;
				el.setAttribute('id', uniqueId);

				// Sekvenciální inicializace s 500ms zpožděním mezi každým videem
				setTimeout(() => {
					this.createYouTubePlayer(uniqueId, youtubeId);
				}, 1000 * index);
			}
		});
	}

	createYouTubePlayer(elementId, videoId) {
		if (!window.YT) {
			// Načtení YouTube IFrame API, pokud ještě není načteno
			const tag = document.createElement('script');
			tag.src = 'https://www.youtube.com/iframe_api';
			const firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

			// Po načtení API vytvořte přehrávač
			window.onYouTubeIframeAPIReady = () => {
				this.createPlayer(elementId, videoId);
			};
		} else {
			this.createPlayer(elementId, videoId);
		}
	}

	createPlayer(elementId, videoId) {
		if (window.YT && YT.Player) {
			new YT.Player(elementId, {
				width: '480',
				height: '270',
				videoId: videoId,
				playerVars: { autoplay: 0, controls: 1 },
				events: {
					onReady: this.onPlayerReady,
				},
			});
		} else {
			console.error('YT.Player is not available.');
		}
	}

	onPlayerReady(event) {
		// Ensure the player supports postMessage
		if (window.postMessage) {
			event.target.playVideo();
		} else {
			console.error('Browser does not support postMessage.');
		}
	}

	static youtubeParseUrl(url) {
		const regExp =
			/^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
		const match = url.match(regExp);
		return match && match[2].length === 11 ? match[2] : null;
	}
}

naja.registerExtension(new OEmbed());
