import naja from 'naja';

class MapsLinks {
	constructor() {
		this.buttonTemplate = `<a href="%link%" target="_blank" rel="noopener" class="button button-logo d-block mb-1"><img src="%logo%" alt="logo"></a>`;
	}

	initialize(naja) {
		naja.addEventListener('success', () => this.make());
		this.make();
	}

	make() {
		$('[data-maps]').on('click', e => this.buttonClick(e));
	}

	buttonClick(event) {
		const element = $(event.currentTarget);
		const settings = element.data('maps');
		const id = element.attr('id')
			? element.attr('id')
			: (Math.random() + 1).toString(36).substring(7);
		element.attr('id', id);

		this.reset(id, () => {
			const el = $(
				`<div class="button-list mb-3 pb-3 border-bottom" id="${id}"></div>`,
			);
			el.hide();

			for (let i in settings.services) {
				const service = settings.services[i];
				const t = this.buttonTemplate;
				const logo = this.getLogo(service);
				const url = this.createUrl(
					service,
					settings.type,
					settings.address,
					settings.gps,
				);
				if (logo !== null && url !== null) {
					el.append(t.replace('%link%', url).replace('%logo%', logo));
				}
			}

			element.after(el);
			el.slideDown(100);
		});
	}

	reset(id, callback) {
		const els = $('.button-list');
		if (els.length === 1 && els.attr('id') === id) {
			els.slideUp(100);
			setTimeout(() => {
				els.remove();
			}, 100);
		} else if (els.length > 0) {
			els.slideUp(100);
			setTimeout(() => {
				els.remove();
				callback();
			}, 100);
		} else {
			callback();
		}
	}

	createUrl(service, type, address, gps = null, zoom = 17) {
		switch (type) {
		case 'query':
			switch (service) {
			case 'googlemaps':
				return (
					`https://www.google.com/maps/place/${encodeURIComponent(
						address,
					)}` +
							(gps !== null
								? `/@${gps[0]},${gps[1]},${zoom}z/`
								: '')
				);
			case 'mapycz':
				return (
					`https://mapy.cz/zakladni?q=${encodeURIComponent(
						address,
					)}&source=addr&z=${zoom}` +
							(gps !== null ? `&x=${gps[1]}&y=${gps[0]}` : '')
				);
			case 'outdooractive':
				return (
					`https://www.outdooractive.com/cs/map/#area=*&q=${encodeURIComponent(
						address,
					)}&zc=${zoom}` +
							(gps !== null ? `,${gps[1]},${gps[0]}` : '')
				);
			}
			break;
		case 'navigation':
			switch (service) {
			case 'googlemaps':
				return (
					`https://www.google.com/maps/dir//${encodeURIComponent(
						address,
					)}` +
							(gps !== null
								? `/@${gps[0]},${gps[1]},${zoom}z/`
								: '')
				);
			case 'mapycz':
				return (
					`https://mapy.cz/zakladni?q=${encodeURIComponent(
						address,
					)}&source=addr&z=${zoom}` +
							(gps !== null ? `&x=${gps[1]}&y=${gps[0]}` : '')
				);
			}
			break;
		case 'search':
			switch (service) {
			case 'googlemaps':
				return (
					`https://www.google.com/maps/search/${encodeURIComponent(
						address,
					)}` +
							(gps !== null
								? `/@${gps[0]},${gps[1]},${zoom}z/`
								: '')
				);
			case 'mapycz':
				return (
					`https://mapy.cz/zakladni?q=Tipy%20na%20v%C3%BDlet&z=${zoom}` +
							(gps !== null ? `&x=${gps[1]}&y=${gps[0]}` : '')
				);
			case 'outdooractive':
				return `https://www.outdooractive.com/cs/routes/#area=*&filter=r-fullyTranslatedLangus-,r-onlyOpened-,sb-sortedBy-0&q=${encodeURIComponent(
					address,
				)}`;
			}
			break;
		}
		return null;
	}

	getLogo(service) {
		return require(`../../images/logo-${service}.svg`).default;
	}
}

naja.registerExtension(new MapsLinks());
